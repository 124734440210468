/*Uncheck icon override*/
.coreApprovalCheckbox div.dx-datagrid-rowsview div[aria-checked="false"] .dx-checkbox-icon:before {
    content: "\f074";
    font-size: 14px;
    color: white;
    font: 14px/1 DXIcons;
    font-size: 13px;
    text-align: center;
    line-height: 11px;
}

.coreApprovalCheckbox .dx-checkbox-icon {
    background-color: red;
}

.coreApprovalCheckbox .dx-checkbox-checked .dx-checkbox-icon {
    background-color: green;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 2px;
}

.coreApprovalCheckbox .dx-datagrid-rowsview .dx-data-row:not(.dx-edit-row) .dx-cell-modified, .dx-datagrid-rowsview .dx-adaptive-detail-row:not(.dx-edit-row) .dx-cell-modified {
    background-color: rgba(40, 188, 196, 0.39);
}

.coreApprovalCheckbox .dx-checkbox-indeterminate .dx-checkbox-icon::before {
    content: "";
}

.coreApprovalCheckbox .dx-checkbox-indeterminate .dx-checkbox-icon {
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 2px;
}

    /*Checked icon override*/
    /*.dx-checkbox-checked .dx-checkbox-icon:before {
    content: "\f006";
    color: green;
}*/
