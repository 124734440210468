html, body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}

.content {
    line-height: 1.5;

    h2 {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.content-block {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
}

.content-body-full-height {
    height: calc(100vh - 150px);
    min-height: 200px;
}

.responsive-paddings {
    padding: 20px;

    .screen-large & {
        padding: 40px;
    }
}

.dx-card.wide-card {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    border-right: 0;
    border-left: 0;
}

.full-height-scrollable > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
    height: 100%;

    &>.dx-scrollview-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}

.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
    padding-top: 10px;
}

.dx-field {
    display: block;
}

$side-panel-min-width: 60px;
