/* You can add global styles to this file, and also import other style files */
$grid-header-background-shading: #bdbdbd;
$grid-header-text: rgba(0,0,0,0.75);
$grid-header-search-bar-rgba: rgba(0,0,0,0.10); 
$grid-background-shading: lightgray;
$data-content-margin-for-utility-drawer: 50px;
$core-dark-on-dark-background: #3f3f4a;
$core-disabled-button-background: rgba(0,0,0,0.3);
$core-border-radius: 4px;
$core-box-shadow: 0px 0px 15px rgba(0,0,0,.1);
$header-zindex: 10;
$drawer-zindex: $header-zindex - 1;
$under-drawer-zindex: $drawer-zindex - 1;
$drawer-content-zindex: $drawer-zindex + 1;
$on-top-of-drawer-zindex: $drawer-content-zindex + 1;
$core-warning-color: #FCCD07;
$spinning-gears-height: 150px;
$spinning-gears-width: 150px;
$header-height: 50px;
$viewport-height: calc(100vh - #{$header-height});
$nav-max-width: 250px;
$nav-min-width: 60px;

::ng-deep .shaded {
    background-color: $grid-background-shading;
}

::ng-deep .dx-pager {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: 1px solid $grid-header-background-shading !important;
    border-radius: $core-border-radius;
}

::ng-deep .core-button {
    font-size: inherit;
    font-weight: bold;
    text-decoration: unset !important;
}

.display-none {
    display: none;
}

.content-block {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
    margin-bottom: 0;
}

.spinning-gears-load-panel .dx-overlay-content {
    max-height: $spinning-gears-height !important;
    max-width: $spinning-gears-width !important;
    height: $spinning-gears-height !important;
    width: $spinning-gears-width !important;
    padding: 0px !important;

    .dx-loadpanel-content-wrapper {
        height: 100%;

        .dx-loadpanel-indicator {
            height: $spinning-gears-height !important;
            width: $spinning-gears-width !important;
        }
    }
}

.stacked-accordion {
    &, .dx-accordion-wrapper, ::ng-deep .dx-accordion-item {
        background-color: transparent !important;
        border-radius: $core-border-radius;
    }
    .dx-accordion-item-title::before {
        top: 3px !important;
    }
    .dx-accordion {
        margin: 3px 0px 5px;
    }
    .dx-accordion-item-body {
        padding: 0px !important;
    }
    .dx-accordion-item-title {
        &, &.dx-state-hover {
            text-transform: capitalize;
            font-size: 13px;
            height: 25px;
            padding-top: 5px;
            background-color: white;
            border-radius: $core-border-radius;
            text-align: center;
        }
    }
}

.core-dark-mode .stacked-accordion.dx-accordion {
    .dx-accordion-item .dx-accordion-item-title {
        &, &.dx-state-hover {
            background-color: #363640;
        }
    }
}

.toolbar-button-activated {
    background-color: #12dd1285;
    box-shadow: inset 0px 2px 2px 1px #00000038;
}

.dx-dashboard-title-toolbar .toolbar-button-activated {
    border-radius: 100%;
}

::ng-deep .dx-widget.dx-button .dx-button-content {
    display: flex;
}

::ng-deep #report-params-button, ::ng-deep #date-account-selector-button {
    color: white;
    background-color: var(--baseAccent);

    .dx-icon {
        color: white;
    }

    &:hover {
        color: white;
        opacity: 0.8;
    }
}

::ng-deep .core-dark-mode.dx-swatch-base-dark .dx-button.dx-button-default {
    color: white;

    .dx-icon {
        color: white;
    }
}

/* * * < BI Skin styles > * * */
.dx-swatch-base-bi .processing-actions-menu .processing-action.run-cycle .dx-icon.dx-icon-spindown {
    border-left-color: #888;
    color: white;
}

.dx-swatch-base-bi .processing-grids .dx-dropdownbutton-has-arrow.dx-state-hover .dx-icon.dx-icon-spindown {
    color: white;
}

.dx-swatch-base-bi .dx-button.dx-button-success {
    background-color: var(--baseAccent);
}

.dx-swatch-base-bi .dx-button.dx-button-success.dx-state-hover {
    background-color: var(--baseAccent);
    opacity: 0.8;
}
/* * * </ BI Skin styles > * * */


/* * * < Dark Mode styles > * * */
.dx-viewport .content-block {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
    margin-bottom: 0;
}

.core-dark-mode .core-component .dx-datagrid-headers td, 
.core-dark-mode .core-component .dx-treelist-headers td {
    background-color: #363640 !important;
    color: white;
}

.core-dark-mode.dx-swatch-base-dark .dx-field, .core-dark-mode.dx-swatch-base-bi-dark .dx-field {
    display: block;
}

.core-dark-mode.dx-swatch-base-dark .dxrd-toolbox-item .dxrd-image-padding, .core-dark-mode.dx-swatch-base-bi-dark .dxrd-toolbox-item .dxrd-image-padding {
    box-sizing: content-box;
}

.core-dark-mode .dx-dashboard-section > h4 {
    font-size: 10px;
}

.core-dark-mode .dx-button.dx-button-normal,
.core-dark-mode .dx-widget.dx-dropdownbutton {
    border: 1px solid #555;
}

.core-dark-mode .dx-drawer-panel-content .dx-button.dx-button-normal,
.core-dark-mode .core-component .dx-button.dx-button-normal,
.core-dark-mode .core-component .dx-widget.dx-dropdownbutton,
.core-dark-mode .header-toolbar .dx-button.dx-button-normal,
.core-dark-mode .core-dashboard-viewer .dx-button.dx-button-normal {
    border: none;
}

::ng-deep .toast-success {
    background-color: var(--successToastColor);
}
/* * * </ Dark Mode styles > * * */
